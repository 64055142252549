/**
 * JQUERY
 */
var $ = require('jquery');
global.jQuery = $;
var jQueryBridget = require('jquery-bridget');
// flickity kullanımı için bridget gerekiyormuş https://flickity.metafizzy.co/extras.html#browserify
var Flickity = require('flickity');
jQueryBridget( 'flickity', Flickity, $ );

require('./smooth-scroll.min');
require('./typed.min');
require('./scripts');

/**
 * ANGULAR
 */

require('angular');
require('angular-animate');
require('angular-promise-buttons');

require("angular-cookies");

require('./config');

(function () {
    'use strict';

    angular.module("delivato-landing", ["env.conf",'angularPromiseButtons',"ngAnimate","ngCookies"])

        .config(["$httpProvider", function ($httpProvider) {
            $httpProvider.interceptors.push('AuthInterceptor');
        }])

        .controller("NavbarCtrl", ["$scope","$cookies","UserFactory", function($scope,$cookies,UserFactory) {
            $scope.changeLang = function (lang) {
                var now = new Date();
                $cookies.put("lang", lang, { path: "/", expires : new Date(now.getFullYear()+1, now.getMonth(), now.getDate()) });
                window.location = "/" + lang + "/";
            };

            $scope.loggedIn = false;

            UserFactory.getUser().then(function success(response) {
                console.log("success",response);
                $scope.loggedIn = true;
            }, function (error) {
                console.log("error",error);
                $scope.loggedIn = false;
            });

        }])

        .controller('LoginCtrl', ['UserFactory','$http', function (UserFactory, $http) {
            'use strict';
            var vm = this;
            vm.login = login;
            vm.signup = signup;
            vm.logout = logout;
            vm.godash = godash;

            // initialization

            UserFactory.getUser().then(function success(response) {
                vm.user=response;
            }, function (error) {
                //console.log(error);
            });

            function login(username, password) {
                return UserFactory.login(username, password).then(function success(response) {
                    console.log(response);
                    if (!response.data.redirectTo){ // birden fazla company var
                        window.location="/app";
                    }

                }, handleError);
            }

            function logout() {
                UserFactory.logout();
            }

            function signup(username, password){
                return UserFactory.signup(username, password).then(function success(response) {
                    window.location = "/" + lang + "/verify";
                }, handleError);
            }

            function handleError(response) {
                vm.errored = true;
            }
            function godash(){
                window.location = '/app';
            }
        }])

        .controller('HomeCtrl', ['$scope','$http', 'UserFactory', 'API_URL', function ($scope, $http, UserFactory, API_URL) {
            'use strict';
            $scope.email = "";

            $scope.register = function () {
                if($scope.email) {
                    $http.post(API_URL + "/api/auth/betalist",{mail: $scope.email}).then(function (res) {
                        $scope.success = true;
                    }, function (err) {
                        $scope.error = true;
                    });
                }
            };

        }])

        .controller('VerifyCtrl', ['$http','UserFactory', function ($http, UserFactory) {
            'use strict';
            var vm = this;
            vm.verify = verify;
            vm.reSendVerification = reSendVerification;

            function reSendVerification(){
                vm.message = null;
                vm.errorMessage = null;
                UserFactory.reSendVerification().then(function successCallback(response) {
                    console.log(response);
                    vm.message = "Verification E-mail sent again."
                }, function errorCallback(response) {
                    console.log(response);
                    vm.message = null;
                    vm.errorMessage = "E-mail arrival may take up to 3 minutes, wait a little bit, if your e-mail dont arrive then click re-send e-mail again.";
                });
            }

            function verify(code){
                vm.message = null;
                vm.errorMessage = null;

                return UserFactory.verify(code).then(function successCallback(response) {
                    console.log(response);
                    window.location= "/"+lang + "/completeregistration";
                }, function errorCallback(response) {
                    vm.errorMessage = "Seems that verification code you've entered isn't quite correct, try again.";
                });
            };
        }])

        .controller('RecoverCtrl', ['$http','UserFactory', function ($http, UserFactory) {
            'use strict';
            var vm = this;
            vm.recover = recover;
            vm.mailSent = false;
            vm.setPassword = setPassword;
            vm.recoverCode = getParameterByName('v');

            console.log(vm.location);

            function recover() {
                UserFactory.recover(vm.email).then(function successCallback(response) {
                    vm.errored = false;
                    vm.mailSent = true;
                }, function errorCallback(response) {
                    vm.errorCode = response.status;
                    console.log(response);
                    vm.errored = true;
                });
            }

            function setPassword() {
                if (vm.newpwd===vm.newpwdagain){
                    UserFactory.setPassword(vm.newpwd, vm.recoverCode).then(function successCallback(response) {
                        window.location = "/app";
                    }, function errorCallback(response) {

                    });
                } else {
                    vm.errored = true;
                }
            }
        }])

        .controller('CompleteCtrl', ['$http','$scope','UserFactory', 'API_URL', function ($http,$scope,UserFactory, API_URL) {
            'use strict';
            var vm = this;
            vm.setDetails = setDetails;
            vm.slugify = slugify;
            vm.checkSlug = checkSlug;
            vm.availability = 0; // 0- no value to check 1- available 2-not available

            $scope.$watch('vm.companyName', function() {
                vm.companyUrl = slugify(vm.companyName);
            });

            $scope.$watch('vm.companyUrl', function() {
                if(vm.companyUrl){
                    checkSlug(vm.companyUrl);
                } else {
                    vm.availability=0;
                }
            });

            function setDetails(companyName, companyUrl){
                return UserFactory.setDetails(companyName,companyUrl).then(function successCallback(response) {

                }, function errorCallback(response) {
                    vm.message = "THIS USERNAME ALREADY EXISTS";
                    vm.availability=2;
                });
            };

            function checkSlug(slug){
                $http.post(API_URL + '/api/company/check', {
                    slug: slug,
                }).then(function successCallback(response) {
                    vm.availability=1;
                    vm.message = "THIS URI IS AVAILABLE";
                }, function errorCallback(response) {
                    vm.availability=2;
                    vm.message = "NOT AVAILABLE";
                });
            }

            function slugify(text) {
                if (text){
                    return text.toString().toLowerCase()
                        .replace(/\s+/g, '-')           // Replace spaces with -
                        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                        .replace(/^-+/, '')             // Trim - from start of text
                        .replace(/-+$/, '');            // Trim - from end of text
                }
            }

        }])

        .controller('CompaniesCtrl', ['$http','$scope', 'TokenLibrary', 'API_URL','$rootScope', function($http,$scope, TokenLibrary, API_URL,$rootScope){
            'use strict';
            var vm = this;
            vm.select = selectCompany;
            vm.goto = goto;

            vm.clientAccs = [];
            vm.ownerAccs = [];
            $http.get(API_URL+'/api/auth/companies')
                .then(function(response){
                    console.log(response);

                    for (var i=0;i<response.data.restrictions.length;i++){
                        for (var b=0;b<response.data.companies.length;b++){
                            if(response.data.companies[b]._id == response.data.restrictions[i]._id)
                            {
                                switch(response.data.restrictions[i].type){
                                    /*
                                    * clientlar ayrıldı ancak burda sahibi olduğun ve edit hakkına sahip olduğun companyler olarak ayırabiliriz
                                    * */
                                    case 0:
                                        vm.ownerAccs.push(response.data.companies[b]);
                                        break;
                                    case 2:
                                        vm.clientAccs.push(response.data.companies[b]);
                                        break;
                                }
                                break;
                            }
                        }
                    }

                })
                .catch(function(err){
                    window.location = "/" + lang;
                });


            function selectCompany(company){
                return $http.post(API_URL+'/api/auth/select', {
                    slug: company.slug
                }).then(function success(response) {
                    TokenLibrary.setLib({accessToken: response.data.token});
                    window.location = '/app';
                    return response;
                });
            }

            function goto(company){
                window.location = ((company.domain) ? "http://" + company.domain : $rootScope.domainPrefix + company.slug + $rootScope.domainSuffix);
            }

        }])

        .factory('UserFactory', ['$http', 'API_URL', 'TokenLibrary', '$q', function ($http, API_URL, TokenLibrary, $q) {
            'use strict';
            return {
                login: login,
                logout: logout,
                signup: signup,
                verify: verify,
                setDetails: setDetails,
                getUser: getUser,
                reSendVerification: reSendVerification,
                recover : recover,
                setPassword: setPassword
            };

            var _user;

            function login(mail, password) {
                return $http.post(API_URL + '/api/auth/login', {
                    mail: mail,
                    password: password
                });
            }

            function recover(mail){
                return $http.post(API_URL + '/api/auth/recover', {
                    mail: mail
                });
            }

            function setPassword(pwd, recoverCode){
                return $http.post(API_URL + '/api/auth/setpassword', {
                    recoverCode: recoverCode,
                    password: pwd
                });
            }

            function signup(mail, password) {
                return $http.post(API_URL + '/api/auth/register', {
                    mail: mail,
                    password: password
                });
            }

            function verify(code) {
                return $http.post(API_URL + '/api/auth/verify', {
                    verification: parseInt(code)
                });
            }

            function reSendVerification(code) {
                return $http.post(API_URL + '/api/auth/verification');
            }

            function setDetails(companyName, companyUrl){
                return $http.post(API_URL + '/api/company', {
                    name: companyName,
                    slug: companyUrl
                }).then(function successCallback(response) {
                    window.location = "/app";
                }, function errorCallback(response) {
                });
            }

            function logout() {
                TokenLibrary.clear(); // tokeni null gönderince removeItem oluyo, siliyo
                window.location = "/"+ lang + '/login';
            }

            function getUser() {
                if (TokenLibrary.getRefreshToken()) {
                    if (_user) return _user;
                    _user = $http.get(API_URL + '/api/auth');
                    return _user;
                } else {
                    //TODO : tokenlibrary.clear kaldırmanın yan etkileri var mı
                    //TokenLibrary.clear();
                    return $q.reject({ data: 'client has no auth token' });
                }
            }
        }])

        .factory('AuthInterceptor', ["TokenLibrary", "$q", "API_URL", function (TokenLibrary, $q, API_URL) {
            'use strict';

            var requestInterceptor = function(req) {
                req.headers = req.headers || {};
                var defer = $q.defer();

                if (req.url.indexOf(API_URL + '/api/auth/register') > -1){
                    defer.resolve(req);
                } else if (req.url.indexOf(API_URL + '/api/auth/login') > -1 || req.url.indexOf(API_URL + '/api/auth/recover') > -1 || req.url.indexOf(API_URL + '/api/auth/setpassword') > -1){
                    defer.resolve(req);
                } else if (req.url.indexOf(API_URL + '/api/auth/token') > -1){
                    //console.log("refresh token req: " + req.url);
                    req.headers.Authorization = 'Bearer ' + TokenLibrary.getRefreshToken();
                    defer.resolve(req);
                } else if (req.url.indexOf(API_URL + '/api') > -1){
                    //console.log("api req: " + req.url);
                    TokenLibrary.getLib('accessToken').then(function (token) {
                        req.headers.Authorization = 'Bearer ' + token;
                        defer.resolve(req);
                    });
                } else {
                    defer.resolve(req);
                }

                return defer.promise;
            };


            var responseInterceptor = function (res) {
                var tokenType = res.headers('X-Delivato-Token');
                console.log("res.token:", tokenType);
                console.log(res.data);
                if (tokenType) {
                    if (tokenType == "accessToken") {
                        TokenLibrary.setLib(res.data);
                    } else if (tokenType == "refreshToken") {
                        TokenLibrary.setRefreshToken(res.data);
                    }

                    if (res.data.redirectTo) window.location= "/" + lang + "/" + res.data.redirectTo;
                }

                return res;
            };

            return {
                request: requestInterceptor,
                response: responseInterceptor
            };

        }])

        .factory('TokenLibrary', ["$window", "$injector", "API_URL", "$q",function ($window, $injector, API_URL, $q) {
            'use strict';
            var storage = $window.localStorage;
            var sessionStorage = $window.sessionStorage;
            var refreshKey = 'refresh-token';
            var libKey = 'token-lib';
            var lib = {};
            var currentPromise;

            return {
                setRefreshToken: setRefreshToken,
                setLib: setLib,
                getRefreshToken: getRefreshToken,
                getLib: getLib,
                clear: clear
            };

            function setRefreshToken(data) {
                storage.setItem(refreshKey, data.refreshToken);
            }

            function getRefreshToken() {
                return storage.getItem(refreshKey);
            }

            function setLib(data) {
                if (data) {
                    lib = data;
                    sessionStorage.setItem(libKey, $window.btoa(JSON.stringify(data)));
                }
            }

            function getLib(prop) {
                var defer = $q.defer();
                if (!lib || angular.equals(lib, {})) {
                    if (sessionStorage.getItem(libKey)) lib = JSON.parse($window.atob(sessionStorage.getItem(libKey)));
                }
                console.log("getlib:", lib);
                if (lib && !angular.equals(lib, {}) && new Date(lib.expiration) > new Date()) {
                    console.log("lib var expiration geçmemiş");
                    if (prop) defer.resolve(lib[prop]);
                    else defer.resolve(lib);
                } else {
                    if (!lib || angular.equals(lib, {})) console.log("lib yok");
                    console.log("expiration geçmiş", lib.expiration);
                    if (currentPromise) {
                        currentPromise.then(function (response) {
                            if (prop) defer.resolve(lib[prop]);
                            else defer.resolve(lib);
                        });
                    } else {
                        var $http = $injector.get('$http');
                        currentPromise = $http.get(API_URL + '/api/auth/token').then(function (response) {
                            setLib(response.data);
                            currentPromise = null;
                            if (prop) defer.resolve(lib[prop]);
                            else defer.resolve(lib);
                        });
                    }

                }

                return defer.promise;
            }

            function clear() {
                storage.removeItem(refreshKey);
                sessionStorage.removeItem(libKey);
                lib = {};
            }
        }]);

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

})();